import React from 'react';
import LayoutES from '../components/layout.es';
import Seo from '../components/seo.es';
import SolutionsBlockES from '../components/solutionsblock.component.es';
import Benefit from '../components/benefit.component';
import BottomCTA from '../components/bottomCTA.component.es';

import '../styles/solution.scss';

import Zoom from 'react-medium-image-zoom';
import '../styles/imagezoom.css';

export default function SolutionRPA() {
	return (
		<LayoutES>
			<Seo title='Automatización inteligente' />
			<section id='introSolution'>
				<div className='solutionBg solution-orchestration'>
					<div id='introSolution-text'>
						<h1>Orquestación de servicios</h1>
						<p>El matrimonio perfecto entre tecnología y negocio</p>
					</div>
				</div>
			</section>
			<section id='benefitsSolution'>
				<Benefit posImage='R' imageUrl='/images/kuflow_CICDCA.png'>
					<h2>CI/CD/CA (Continuous Automation)</h2>
					<p>
						Todo lo bueno de{' '}
						<strong>los pipelines que ya conoces, pero integrados</strong>{' '}
						perfectamente en un proceso interdepartamental e
						interorganizacional.
					</p>

					<p>
						Hablando en plata, que una web se ponga pública cuando el CEO haga
						click en “sí” o que un servidor entre en modo mantenimiento cuando
						el cliente confirme.
					</p>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_RPAdetails_01_ES.png'
							width='280'
							style={{ margin: '0 auto' }}
							alt='RPA reintentándolo'
						/>
					</Zoom>
				</Benefit>
				<Benefit posImage='L' imageUrl='/images/kuflow_visible-processes.png'>
					<h2>Haz visible tus procesos</h2>
					<p>
						Cuando hay 8 equipos trabajando en paralelo ves pipelines y
						despliegues como quien ve llover.
					</p>
					<p>
						Con KuFlow vas a poder <strong>dar sentido y contexto</strong> a
						cada uno de ellos.
					</p>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_processlist_01_ES.png'
							width='280'
							style={{ margin: '0 auto' }}
							alt='Lista de procesos'
						/>
					</Zoom>
				</Benefit>
				<Benefit posImage='R' imageUrl='/images/kuflow_world-class-rpa.png'>
					<h2>Resiliencia as a Service</h2>
					<p>
						KuFlow está diseñado para ser <strong>resiliente</strong>. Esto
						significa:
					</p>
					<ol>
						<li>
							Se encarga de los <strong>retries</strong>.
						</li>
						<li>
							Gestiona los <strong>timeouts</strong> en las conexiones.
						</li>
						<li>
							Se maneja perfectamente en <strong>procesos asíncronos</strong>.
						</li>
						<li>
							<strong>Mantiene los estados de las peticiones</strong> para que
							no se le escape nada.
						</li>
					</ol>
				</Benefit>
				<Benefit posImage='L' imageUrl='/images/kuflow_synergies.png'>
					<h2>¿Fan de las synergies? Prepárate</h2>
					<p>
						Si estás intentando convencer a un muggle dev de lo que hace KuFlow,
						tenemos las palabras adecuadas:
					</p>
					<quote>
						KuFlow es un{' '}
						<strong>
							low-maintenance, resilient orchestration workflow engine as a
							service
						</strong>{' '}
						para la <strong>automation de business processes </strong>
						que permite una <strong>visión top-down 360º</strong> con{' '}
						<strong>actionable insights</strong>
						perfecto para tu <strong>business intelligence dashboard</strong>.
					</quote>
					<p>
						Una pena no tener también algo de blockchain o IoT, pero tampoco
						queríamos pasarnos de flipados.
					</p>
				</Benefit>
			</section>
			<SolutionsBlockES />
			<BottomCTA />
		</LayoutES>
	);
}
